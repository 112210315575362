<template>
  <div>
    <div
      v-if="Object.keys(prebidpartner).length === 0"
      class="d-flex justify-content-center mb-1"
    >
      <b-spinner label="Loading..." />
    </div>
    <div v-else>
      <breadcrumbs
        :breadcrumb-items="breadcrumbItems"
      />
      <b-card>
        <PrebidPartnerToolbar
          v-if="Object.keys(prebidpartner).length !== 0"
          :prebidpartner="prebidpartner"
          closing
        />
      </b-card>
      <b-form @submit.prevent>
        <validation-observer ref="validationPartnerRules">
          <b-tabs
            pills
          >
            <b-tab active>
              <!-- title -->
              <template #title>
                <feather-icon
                  icon="SettingsIcon"
                  size="18"
                  class="mr-50"
                />
                <span class="font-weight-bold">{{ $t('Settings') }}</span>
              </template>
              <b-card>
                <div class="head-card">
                  <div class="head-card-top">
                    <h4>{{ $t('Main settings') }}</h4>
                  </div>
                  <hr>
                </div>
                <b-row>
                  <b-col md="6">
                    <b-form-group
                      :label="$t('dsppartners.name')"
                      label-for="i-name"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="name"
                        rules="required"
                      >
                        <b-input-group
                          class="input-group-merge"
                          :class="errors.length == 0 || 'is-invalid'"
                        >
                          <b-input-group-prepend is-text>
                            <feather-icon icon="LayoutIcon" />
                          </b-input-group-prepend>
                          <b-form-input
                            id="i-name"
                            v-model="prebidpartner.name"
                            :placeholder="$t('dsppartners.name')"
                            :state="errors.length > 0 ? false:null"
                            autocomplete="name"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group
                      :label="$t('partners.phx_rate')"
                      label-for="i-phx_rate"
                    >
                      <b-input-group class="input-group-merge">
                        <b-input-group-prepend is-text>
                          <feather-icon icon="PercentIcon" />
                        </b-input-group-prepend>
                        <b-form-input
                          id="i-phx_rate"
                          v-model="prebidpartner.phx_rate"
                          placeholder="10"
                          autocomplete="phx_rate"
                        />
                      </b-input-group>
                    </b-form-group>
                    <b-form-group
                      :label="$t('profile.notes')"
                      label-for="i-notes"
                    >
                      <b-input-group class="input-group-merge">
                        <b-input-group-prepend is-text>
                          <feather-icon icon="FileTextIcon" />
                        </b-input-group-prepend>
                        <b-form-textarea
                          id="i-notes"
                          v-model="prebidpartner.notes"
                          :placeholder="$t('profile.notes')"
                        />
                      </b-input-group>
                    </b-form-group>

                    <b-form-group
                      :label="$t('sites.status')"
                      label-for="i-status"
                    >
                      <b-form-select
                        v-model="status.value"
                        :options="statusesLocale"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6" />
                </b-row>
                <b-row>
                  <b-col
                    md="12"
                    class="d-flex align-items-center justify-content-end mt-1"
                  >
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-secondary"
                      class="mr-0 mr-sm-1"
                      :to="{ path: '/prebidpartners' }"
                    >
                      <feather-icon
                        icon="ChevronLeftIcon"
                        class="mr-50"
                      />
                      <span class="align-middle">{{ $t('form.back') }}</span>
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      @click="prebidPartnerUpdate"
                    >
                      <span class="align-middle">{{ $t('form.save') }}</span>
                      <feather-icon
                        icon="ChevronRightIcon"
                        class="ml-50"
                      />
                    </b-button>
                  </b-col>
                </b-row>
              </b-card>
            </b-tab>
            <!--/ general tab -->
          </b-tabs>
        </validation-observer>
      </b-form>
    </div>
  </div>
</template>

<script>
import useJwt from '@/auth/jwt/useJwt'
import options from '@/libs/options'
import {
  BRow,
  BCol,
  BTabs,
  BTab,
  BCard,
  BForm,
  BFormGroup,
  // BInputGroupAppend,
  BInputGroupPrepend,
  BInputGroup,
  BFormInput,
  BSpinner,
  VBTooltip,
  BFormSelect,
  BButton,
  BFormTextarea,
} from 'bootstrap-vue'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'
import {
  required,
  integer,
  min,
} from '@validations'
import Breadcrumbs from '@/views/ui/breadcrumbs/Breadcrumbs.vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import PrebidPartnerToolbar from './PrebidPartnerToolbar.vue'

export default {
  components: {
    BRow,
    BCol,
    BTabs,
    BTab,
    BCard,
    Breadcrumbs,
    BForm,
    BFormGroup,
    // BInputGroupAppend,
    BInputGroupPrepend,
    BInputGroup,
    BFormInput,
    BFormSelect,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
    BButton,
    BFormTextarea,
    PrebidPartnerToolbar,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      min,
      required,
      integer,
      prebidpartner: [],
      breadcrumbItems: [],
      status: {},
      ...options,
    }
  },
  computed: {
    statusesLocale() {
      return this.statuses.map(s => {
        const item = {}
        item.value = s.value
        item.text = this.$i18n.t(`selects.statuses.${s.value}`)
        return item
      })
    },
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        negative     : 'outline-primary',
        active       : 'outline-success',
        inactive     : 'outline-danger',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
  },
  created() {
    this.fetchPrebidPartnerData()
  },
  methods: {
    async fetchPrebidPartnerData() {
      await useJwt.getPrebidPartner(this.$route.params.id)
        .then(response => {
          this.prebidpartner = response.data.prebidpartner || []
          if (this.prebidpartner.status !== '') {
            const statusText = this.prebidpartner.status.charAt(0).toUpperCase() + this.prebidpartner.status.slice(1)
            this.status = { value: this.prebidpartner.status, text: statusText }
          }
          this.setBreadcrumbs()
        })
        .catch(err => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.response.data.errors[0].detail,
              icon: 'EditIcon',
              variant: 'danger',
            },
          })
        })
    },
    prebidPartnerUpdate() {
      this.$refs.validationPartnerRules.validate().then(success => {
        if (success) {
          this.prebidpartner.status = this.status.value
          if (this.prebidpartner.phx_rate === '') {
            this.prebidpartner.phx_rate = 0
          }
          useJwt.setPrebidPartner(this.$route.params.id, { prebidpartner: this.prebidpartner })
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Partner saved',
                  icon: 'EditIcon',
                  variant: 'success',
                },
              })
              this.$router.push('/prebidpartners')
            })
            .catch(err => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: err.response.data.errors[0].detail,
                  icon: 'EditIcon',
                  variant: 'danger',
                },
              })
            })
        }
      })
    },
    setBreadcrumbs() {
      this.breadcrumbItems = [
        {
          text: this.$i18n.t('Prebid partners'),
          to: '/prebidpartners/',
        },
        {
          text: this.prebidpartner.name,
          to: `/prebidpartners/${this.prebidpartner._id}/`,
        },
        {
          text: this.$i18n.t('Settings'),
          active: true,
        },
      ]
    },
  },
}
</script>
